var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-menu', {
    attrs: {
      "close-on-content-click": false,
      "max-width": "290"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "outlined": "",
            "hide-details": "",
            "prepend-icon": _vm.$attrs.icon,
            "placeholder": _vm.$attrs.placeholder,
            "label": _vm.$attrs.label,
            "readonly": "",
            "value": _vm.formattedDate,
            "append-icon": _vm.date ? "mdi-close" : null
          },
          on: {
            "click:append": _vm.clear,
            "input": function input(e) {
              return _vm.$emit("change", e);
            }
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.open,
      callback: function callback($$v) {
        _vm.open = $$v;
      },
      expression: "open"
    }
  }, [_c('v-date-picker', {
    on: {
      "change": function change($event) {
        _vm.open = false;
      },
      "input": function input(e) {
        return _vm.$emit("change", e);
      }
    },
    model: {
      value: _vm.date,
      callback: function callback($$v) {
        _vm.date = $$v;
      },
      expression: "date"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }