var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-autocomplete', {
    attrs: {
      "items": _vm.JOB_STAGES,
      "item-text": "title",
      "item-value": "xid",
      "return-object": "",
      "value": _vm.optionSelected,
      "clearable": "",
      "outlined": "",
      "color": "blue",
      "placeholder": "Job Stage",
      "hide-details": "",
      "prepend-inner-icon": _vm.icon,
      "label": "Stage"
    },
    on: {
      "change": _vm.change,
      "click:prepend-inner": _vm.clickIcon
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }