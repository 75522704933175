var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-dialog', {
    attrs: {
      "max-width": "400px",
      "scrollable": ""
    },
    model: {
      value: _vm.showMenu,
      callback: function callback($$v) {
        _vm.showMenu = $$v;
      },
      expression: "showMenu"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "primary white--text"
  }, [_vm._v("Add a Measurement...")]), _c('v-card-text', {
    staticStyle: {
      "max-height": "50vh"
    }
  }, [_c('v-list', {
    attrs: {
      "dense": ""
    }
  }, _vm._l(_vm.menuItems, function (item, k) {
    return _c('v-list-item', {
      key: k,
      attrs: {
        "link": "",
        "dense": ""
      },
      on: {
        "click": function click($event) {
          return _vm.clickItem(item);
        }
      }
    }, [_c('v-list-item-content', [item.menu ? _c('span', {
      staticClass: "primary--text"
    }, [_vm._v(_vm._s(item.text))]) : _c('span', [_vm._v(_vm._s(item.text))])]), item.menu ? _c('v-list-item-action', [_c('v-icon', {
      attrs: {
        "color": "primary"
      }
    }, [_vm._v("mdi-menu-right")])], 1) : _vm._e()], 1);
  }), 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": _vm.manualEntry
    }
  }, [_c('v-icon', [_vm._v("mdi-keyboard")])], 1), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.showMenu = false;
      }
    }
  }, [_vm._v("Cancel")])], 1)], 1)], 1), _vm._l(_vm.tags, function (tag, i) {
    return _c('v-chip', {
      key: i
    }, [_vm._v(_vm._s(tag))]);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }