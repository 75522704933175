<template>
  <div v-if='!loading'>
    <div v-if='edit' class='mt-4'>
      <v-btn small fab color='primary' class='float-right mx-4' @click='edit=false'><v-icon small>mdi-lock</v-icon></v-btn>
      <v-select outlined dense label='Type' :items='types' v-model='type' @change='save'></v-select>
      <v-select
        v-for='option in options' :key='option.option'
        outlined dense clearable
        :label='option.option' :items='[getChoice(option.option), ...option.choices]' :value='getChoice(option.option)' @change='v=>updateChoice(option.option, v)'
      ></v-select>
    </div>
    <template v-else>
      <v-list dense>
        <v-list-item>
          <v-list-item-content>
            <div class='text-caption secondary--text'>Type</div>
            <div class='text-subtitle-2'>{{type || "Not Selected"}}</div>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn small fab color='primary' @click='edit=true'><v-icon small>mdi-lock-open</v-icon></v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-list-item v-for='option,k in options' :key='k'>
          <v-list-item-content>
            <div class='text-caption secondary--text'>{{option.option}}</div>
            <div class='text-subtitle-2'>{{getChoice(option.option)}}</div>
          </v-list-item-content>
        </v-list-item>
      </v-list>

    </template>

  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>

<script>
import { /* mapGetters, */  mapActions } from 'vuex';
import YAML from 'js-yaml';
//import MenuItems from './MenuItems'

export default {
  name: 'MeasureTags',
  //components: { MenuItems },
  props: {
    tagdata: { type: Object, required: false, default: new Object() },
  },
  data(){
    return {
      edit: false,
      loading: false,
      config: [],
      type: null,
      choices: [],
    }
  },
  computed: {
    types(){ return this.config.map(conf=>conf.type) },
    options(){
      // options from types
      let options = this.config.find(conf=>conf.type==this.type)?.options || [];
      // add current choices
      this.choices.forEach(choice=>{
        if(!options.map(o=>o.option).includes(choice.option)) options.push({ option: choice.option, choices: [] });
      });
      return options;
    }
  },
  watch:{
    showMenu(show){ if(show == true) this.menuItems = this.rootMenuItems; },
  },
  methods: {
    ...mapActions("API", ["API"]),
    //...mapActions("UI", ["PROMPT"]),

    refresh(){
      this.loading = true;
      return this.API({ endpoint: 'measures/tags' })
      .then(config => {
        try{ this.config = YAML.load(config);
        } catch(e){ this.config = []; }
      })
      .finally(() => {
        this.loading = false;
      });
    },

    getChoice(option){
      return this.choices.find(c=>c.option == option)?.choice ?? null;
    },

    updateChoice(option, value){
      let choice = this.choices.find(c=>c.option==option);
      if(choice) choice.choice = value;
      else this.choices.push({ option, choice: value });
      if(value == null) this.choices.splice(this.choices.findIndex(c=>c.option==option), 1);
      this.save();
    },

    save(){
      this.$emit('updated', { type: this.type, choices: this.choices });
    }


  },
  mounted(){
    this.refresh();
    this.type = this.tagdata.type ?? null;
    this.choices = this.tagdata.choices ?? [];
  }
}
</script>
