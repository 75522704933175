<template>
  <div>

    <v-input hide-details @click='clickContact'>
      <template #prepend>
        <v-icon class='mt-2 xprimary--text'>mdi-account-box</v-icon>
      </template>
        <v-textarea
          hide-details outlined auto-grow readonly
          class='pa-0 addressCard' :rows='1' label='Site Contact'
          @click='clickContact'
          :value='input'
        />
    </v-input>


    <!-- DIALOG -->
    <v-dialog scrollable persistent
      :value='dialog.show'
      max-width='600'
    >
      <v-card :loading='dialog.loading' :disabled='dialog.loading' style='max-height:100vh;overflow:auto;'>
        <v-form @submit.prevent='submitDialog'>
          <v-card-title class='primary white--text'>Site Contact</v-card-title>
          <v-card-text class='pt-5' style='max-height:500px; overflow-y: auto;'>
            <v-row>
              <v-col cols=12 v-if='customer'>
                <v-menu>
                  <template #activator="{on, attrs}">
                    <v-btn small class='primary' v-on="on" v-bind="attrs" @click='loadContactBook'>Contact Book</v-btn>
                  </template>
                  <v-list>
                    <v-list-item v-if='!contactBookLoaded'>Loading...</v-list-item>
                    <v-list-item link v-for='c in contactBook' :key='c.xid' @click='fillContact(c)'>{{c.name_first}} {{c.name_last}}</v-list-item>
                    <v-list-item v-if='contactBookLoaded && !contactBook.length'>No Contacts</v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
              <v-col cols=12>
                <v-textarea
                  hide-details outlined auto-grow
                  class='' placeholder='Contact Information'
                  v-model='dialog.input'
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider/>
          <v-card-actions class=''>
            <v-spacer />
            <v-btn large color='secondary float-right' width='150' @click='dialog.show=false'>Cancel</v-btn>
            <v-btn large color='primary float-right' width='150' type='submit'>Update</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.addressCard::v-deep{
  cursor: pointer;
  border-color: #00000061;
  &:hover{ border-color: black; }
  .v-text-field__slot{ cursor: pointer }
  textarea{ cursor: pointer; margin-bottom: 10px; }
}


</style>

<script>
import { /* mapGetters, */  mapActions } from 'vuex';
export default {
  name: 'SiteContact',
  components: {},
  props: {
    value: { type: String, required: false, default: "" },
    customer: { type: Object, required: false, default: null },
  },
  watch: {
    value(v){ this.input = v; }
  },
  data(){
    return {
      input: "",
      dialog: {
        loading: false, show: false,
        input: "",
      },
      contactBook: [],
      contactBookLoaded: false,
    }
  },
  computed: {
  },
  methods: {
    ...mapActions("API", ["API"]),

    clickContact(){
      this.dialog = {
        ...this.dialog,
        show: true,
        input: this.input,
      };
    },

    submitDialog(){
      this.dialog.show = false;
      this.$emit('change', this.dialog.input);
    },

    loadContactBook(){
      if(this.contactBookLoaded) return;
      this.API({ endpoint: `customers/${this.customer.xid}/contacts` })
      .then(contacts => {
        this.contactBook = contacts;
        this.contactBookLoaded = true;
      });
    },

    fillContact(contact){
      let text = [];
      if(this.dialog.input.length) text.push("\n----------");
      text.push(`${contact.name_first} ${contact.name_last}`);
      if(contact.phone_1) text.push(contact.phone_1);
      if(contact.phone_2) text.push(contact.phone_2);
      this.dialog.input += text.join("\n");
    }

  },
  created(){}
}
</script>
