<template>
  <v-container :key='jobId' class='pt-8'>
    
    <div ref='pdfArea'></div>

        <v-row>

          <v-col cols=12 lg=9>

            <v-card class='' :loading='loading' :disabled='loading'>
              <v-card-title class='primary white--text text-body-1 py-0 pr-0'>
                <v-icon dark class='mr-2'>mdi-briefcase</v-icon>
                <span class=''>{{job.number}}</span>
                <v-spacer />
                <Taskflow ref="Taskflow" type="job" :id="jobId"><template #button="{click}"><v-btn icon dark @click='click'><v-icon>mdi-source-merge</v-icon></v-btn></template></Taskflow>
                <v-btn text dark fab small @click='printJob'><v-icon>mdi-printer</v-icon></v-btn>
              </v-card-title>
              <v-card-text class=''>
                <v-row class='py-4 pt-8'>
                  <v-col cols=12 md=6 lg=4 class='pt-0'><CustomerSelector :value='job.customer' @change='updateCustomer' class='my-0'/></v-col>
                  <v-col cols=12 md=6 lg=4 class='pt-0'><v-text-field class='my-0' hide-details outlined label='Customer A/C' :value='job.customer_acc' @change='v=>updateJob({customer_acc:v})' /></v-col>
                  <v-col cols=12 md=6 lg=4 class='pt-0'><StageSelector :value='job.stage' @change='updateStage' class='my-0' /></v-col>
                  <v-col cols=12 md=6 lg=3 class='pt-0'><CalendarInput :value='job.date_enquired' label='Enquired' class='my-0' @change='v=>updateJob({date_enquired:v})' /></v-col>
                  <v-col cols=12 md=6 lg=3 class='pt-0'><CalendarInput :value='job.date_accepted' label='Accepted' class='my-0' @change='v=>updateJob({date_accepted:v})' /></v-col>
                  <v-col cols=12 md=6 lg=3 class='pt-0'><CalendarInput :value='job.date_promised' class='my-0' label='Forecast' @change='v=>updateJob({date_promised:v})' /></v-col>
                  <v-col cols=12 md=6 lg=3 class='pt-0'><CalendarInput :value='job.date_closed' label='Closed' class='my-0' @change='v=>updateJob({date_closed:v})' /></v-col>
                </v-row>
                <v-text-field hide-details outlined label='Description' :value='job.description' @change='v=>updateJob({description:v})' />
                <v-textarea class='mt-4' hide-details outlined auto-grow  label='Details' :value='job.details' @change='v=>updateJob({details:v})' />
              </v-card-text>
            </v-card>

            <v-card class='mt-4'>
              <v-card-title class='primary white--text text-body-1 py-0 pr-0'>
                <v-icon dark class='mr-2'>mdi-note</v-icon>
                Notes
                <v-spacer />
                <v-btn text dark fab small @click='()=>$refs.notes.clickNewNote()'><v-icon>mdi-plus</v-icon></v-btn>
              </v-card-title>
              <v-card-text class='mt-4'>
                <Notes parentType='job' ref='notes' :parentUrl='`jobs/${jobId}`' hideAddButton />
              </v-card-text>
            </v-card>

          </v-col>

          <v-col cols=12 lg=3>

            <v-card class='mb-4' :loading='loading' :disabled='loading'>
              <v-card-title class='primary white--text text-body-1 py-2'>
                <v-icon dark class='mr-2'>mdi-map</v-icon>
                Location
              </v-card-title>
              <v-card-text class='mt-4'>
                <JobAddress :value='job.address' :customer='job.customer' @change='updateAddress' class='' />

                <SiteContact
                  class='mt-4'
                  :value='job.site_contact' @change='v=>updateJob({site_contact: v})'
                  :customer='job.customer'
                />

              </v-card-text>
            </v-card>

            <v-card class='mb-4' :loading='loading' :disabled='loading'>
              <v-card-title class='primary white--text text-body-1 py-2'>
                <v-icon dark class='mr-2'>mdi-cash-register</v-icon>
                Billing
              </v-card-title>
              <v-card-text class='mt-4'>
                <BillingStageSelector class='mb-4' :value='job.billing_stage' @change='updateBillingStage' />
                <v-text-field hide-details outlined label='Purchase Order Number' :value='job.purchase_order' @change='v=>updateJob({purchase_order: v})' />
              </v-card-text>
            </v-card>

            <v-card class='mt-4' :loading="filesLoading" :disabled="filesLoading">
              <v-card-title class='primary white--text text-body-1 py-0 pr-0'>
                <v-icon dark class='mr-2'>mdi-folder</v-icon>
                Files
                <v-spacer />
                <FilePickerInbox #button="{click}" @pick='inboxFilePicked'><v-btn fab small text dark @click='click'><v-icon>mdi-folder-network</v-icon></v-btn></FilePickerInbox>
                <v-btn fab small text dark @click='()=>$refs.files.clickAdd()'><v-icon>mdi-cloud-upload</v-icon></v-btn>
              </v-card-title>
              <v-card-text class='pa-0 py-1'>
                <Files ref='files' :jobId='jobId' />
              </v-card-text>
            </v-card>

            <v-card class='mt-4'>
              <v-card-title class='primary white--text text-body-1 py-0 pr-0'>
                <v-icon dark class='mr-2'>mdi-tape-measure</v-icon>
                Measurements
                <v-spacer />
                <v-btn fab small text dark @click='()=>$refs.measures.clickAdd()'><v-icon>mdi-plus</v-icon></v-btn>
              </v-card-title>
              <v-card-text class='pa-0 py-1'>
                <Measures ref='measures' :jobId='jobId' />
              </v-card-text>
            </v-card>

          </v-col>

        </v-row>

  </v-container>
</template>

<style lang="scss">
.v-main{ overflow-y: auto; }
</style>


<script>
import { mapActions } from 'vuex';
import PrintJS from 'print-js'
//import Contacts from '@/components/Customer/Contacts';
//import Addresses from '@/components/Customer/Addresses';
//import Notes from '@/components/Customer/Notes';
import CustomerSelector from '@/components/Customer/Selector';
import StageSelector from '@/components/Job/StageSelector';
import BillingStageSelector from '@/components/Job/BillingStageSelector';
import JobAddress from '@/components/Job/Address'
import SiteContact from '@/components/Job/SiteContact'
import Notes from '@/components/Common/Notes';
import Files from '@/components/Job/Files'
import Measures from '@/components/Job/Measures'
import CalendarInput from '@/components/Common/CalendarInput'
import FilePickerInbox from '@/components/Common/FilePickerInbox'
import Taskflow from '@/components/Dialogs/Taskflow'
export default {
  name: 'Job',
  components: {
    CustomerSelector, StageSelector, BillingStageSelector, JobAddress, SiteContact, Notes, Files, CalendarInput, Measures,
    FilePickerInbox, Taskflow
    //Contacts, Addresses, Notes,
  },
  props: {
    jobId: { type: String, required: true, },
  },
  data(){ return {
    loading: false,
    filesLoading: false,
    job: {
      number: null,
      description: null,
      customer: null,
      stage: null,
    },
  }},
  watch: {
    jobId(){ this.refreshJob(); },
    $route: { handler(){ this.SET_TITLE('...'); }, immediate: true },
    'job.number': { handler(jobNumber){ this.SET_TITLE(jobNumber); }},
  },
  methods: {
    ...mapActions("UI", ["SET_TITLE", "PROMPT"]),
    ...mapActions("API", ["API"]),
    refreshJob(){
      this.loading = true;
      return this.API({ method: 'GET', endpoint: `jobs/${this.jobId}` })
      .then(job => { this.job = job; })
      .finally(() => { this.loading = false; });
    },

    updateCustomer(customer){
      customer = customer ? customer.xid : null;
      this.updateJob({ customer });
    },

    updateStage(stage){
      stage = stage ? stage.xid : null;
      this.updateJob({ stage });
    },

    updateBillingStage(billing_stage){
      billing_stage = billing_stage ? billing_stage.xid : null;
      this.updateJob({ billing_stage });
    },

    updateJob(data){
      this.loading = true;
      this.API({ method: 'PATCH', endpoint: `jobs/${this.jobId}`, data })
      .finally(() => {
        this.refreshJob()
        this.$refs.notes.refreshNotes();
      });
    },

    updateAddress(address){
      this.API({ method: 'PATCH', endpoint: `jobs/${this.jobId}/address`, data: address })
      .finally(this.refreshJob);
    },

    updateDatePromised(date_promised){
      this.updateJob({ date_promised });
    },

    printJob(){
      this.API({ endpoint: `jobs/${this.jobId}/print` })
      .then(printable => {
        PrintJS({ printable, type: 'pdf', base64: true });
      });
    },

    async inboxFilePicked(inboxFiles){
      if(!inboxFiles.length) return;
      let files = inboxFiles.map(f => f.file.xid);
      this.filesLoading = true;
      for(let i=0; i<files.length;i++){
        let data = { job: this.jobId };
        await this.API({ method: 'POST', endpoint: `filebox/files/${files[i]}/attach`, data });
        await this.$refs.files.refreshFiles();
      }
      this.filesLoading = false;
    }

  },
  mounted(){
    this.refreshJob();
  }
}
</script>
