<template>
  <div>
    <v-autocomplete
      :items='JOB_BILLING_STAGES' item-text='label' item-value='xid' return-object
      :value='optionSelected'
      @change='change' clearable
      outlined color='blue'
      label='Billing Status'
      hide-details
      xclick:prepend='clickIcon'
    >
    </v-autocomplete>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>

<script>
import { mapGetters,  mapActions } from 'vuex';
export default {
  name: 'BillingStageSelector',
  components: {},
  props: {
    value: { type: Object, required: false, default: null },
  },
  data(){
    return {
      optionSelected: null,
    }
  },
  watch: {
    value(newValue){ this.optionSelected = newValue; }
  },
  computed: {
    ...mapGetters("UI", ["JOB_BILLING_STAGES"]),
  },
  methods: {
    ...mapActions("API", ["API"]),
    ...mapActions("UI", ["ALERT", "NAVIGATE"]),

    change(newSelection){
      this.optionSelected = newSelection;
      this.$emit('change', newSelection);
    },

    clickIcon(){ //disabled
      let stage = this.optionSelected?.xid;
      if(!stage) this.ALERT({ title: "Stage", message: "No stage selected" });
      else this.NAVIGATE(`/dash/jobs/${stage}`);
    }

  },
  mounted(){
  }
}
</script>
