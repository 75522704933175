var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    key: _vm.jobId,
    staticClass: "pt-8"
  }, [_c('div', {
    ref: "pdfArea"
  }), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "9"
    }
  }, [_c('v-card', {
    attrs: {
      "loading": _vm.loading,
      "disabled": _vm.loading
    }
  }, [_c('v-card-title', {
    staticClass: "primary white--text text-body-1 py-0 pr-0"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-briefcase")]), _c('span', {}, [_vm._v(_vm._s(_vm.job.number))]), _c('v-spacer'), _c('Taskflow', {
    ref: "Taskflow",
    attrs: {
      "type": "job",
      "id": _vm.jobId
    },
    scopedSlots: _vm._u([{
      key: "button",
      fn: function fn(_ref) {
        var click = _ref.click;
        return [_c('v-btn', {
          attrs: {
            "icon": "",
            "dark": ""
          },
          on: {
            "click": click
          }
        }, [_c('v-icon', [_vm._v("mdi-source-merge")])], 1)];
      }
    }])
  }), _c('v-btn', {
    attrs: {
      "text": "",
      "dark": "",
      "fab": "",
      "small": ""
    },
    on: {
      "click": _vm.printJob
    }
  }, [_c('v-icon', [_vm._v("mdi-printer")])], 1)], 1), _c('v-card-text', {}, [_c('v-row', {
    staticClass: "py-4 pt-8"
  }, [_c('v-col', {
    staticClass: "pt-0",
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "4"
    }
  }, [_c('CustomerSelector', {
    staticClass: "my-0",
    attrs: {
      "value": _vm.job.customer
    },
    on: {
      "change": _vm.updateCustomer
    }
  })], 1), _c('v-col', {
    staticClass: "pt-0",
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "4"
    }
  }, [_c('v-text-field', {
    staticClass: "my-0",
    attrs: {
      "hide-details": "",
      "outlined": "",
      "label": "Customer A/C",
      "value": _vm.job.customer_acc
    },
    on: {
      "change": function change(v) {
        return _vm.updateJob({
          customer_acc: v
        });
      }
    }
  })], 1), _c('v-col', {
    staticClass: "pt-0",
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "4"
    }
  }, [_c('StageSelector', {
    staticClass: "my-0",
    attrs: {
      "value": _vm.job.stage
    },
    on: {
      "change": _vm.updateStage
    }
  })], 1), _c('v-col', {
    staticClass: "pt-0",
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "3"
    }
  }, [_c('CalendarInput', {
    staticClass: "my-0",
    attrs: {
      "value": _vm.job.date_enquired,
      "label": "Enquired"
    },
    on: {
      "change": function change(v) {
        return _vm.updateJob({
          date_enquired: v
        });
      }
    }
  })], 1), _c('v-col', {
    staticClass: "pt-0",
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "3"
    }
  }, [_c('CalendarInput', {
    staticClass: "my-0",
    attrs: {
      "value": _vm.job.date_accepted,
      "label": "Accepted"
    },
    on: {
      "change": function change(v) {
        return _vm.updateJob({
          date_accepted: v
        });
      }
    }
  })], 1), _c('v-col', {
    staticClass: "pt-0",
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "3"
    }
  }, [_c('CalendarInput', {
    staticClass: "my-0",
    attrs: {
      "value": _vm.job.date_promised,
      "label": "Forecast"
    },
    on: {
      "change": function change(v) {
        return _vm.updateJob({
          date_promised: v
        });
      }
    }
  })], 1), _c('v-col', {
    staticClass: "pt-0",
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "3"
    }
  }, [_c('CalendarInput', {
    staticClass: "my-0",
    attrs: {
      "value": _vm.job.date_closed,
      "label": "Closed"
    },
    on: {
      "change": function change(v) {
        return _vm.updateJob({
          date_closed: v
        });
      }
    }
  })], 1)], 1), _c('v-text-field', {
    attrs: {
      "hide-details": "",
      "outlined": "",
      "label": "Description",
      "value": _vm.job.description
    },
    on: {
      "change": function change(v) {
        return _vm.updateJob({
          description: v
        });
      }
    }
  }), _c('v-textarea', {
    staticClass: "mt-4",
    attrs: {
      "hide-details": "",
      "outlined": "",
      "auto-grow": "",
      "label": "Details",
      "value": _vm.job.details
    },
    on: {
      "change": function change(v) {
        return _vm.updateJob({
          details: v
        });
      }
    }
  })], 1)], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "primary white--text text-body-1 py-0 pr-0"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-note")]), _vm._v(" Notes "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "dark": "",
      "fab": "",
      "small": ""
    },
    on: {
      "click": function click() {
        return _vm.$refs.notes.clickNewNote();
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")])], 1)], 1), _c('v-card-text', {
    staticClass: "mt-4"
  }, [_c('Notes', {
    ref: "notes",
    attrs: {
      "parentType": "job",
      "parentUrl": "jobs/".concat(_vm.jobId),
      "hideAddButton": ""
    }
  })], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "3"
    }
  }, [_c('v-card', {
    staticClass: "mb-4",
    attrs: {
      "loading": _vm.loading,
      "disabled": _vm.loading
    }
  }, [_c('v-card-title', {
    staticClass: "primary white--text text-body-1 py-2"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-map")]), _vm._v(" Location ")], 1), _c('v-card-text', {
    staticClass: "mt-4"
  }, [_c('JobAddress', {
    attrs: {
      "value": _vm.job.address,
      "customer": _vm.job.customer
    },
    on: {
      "change": _vm.updateAddress
    }
  }), _c('SiteContact', {
    staticClass: "mt-4",
    attrs: {
      "value": _vm.job.site_contact,
      "customer": _vm.job.customer
    },
    on: {
      "change": function change(v) {
        return _vm.updateJob({
          site_contact: v
        });
      }
    }
  })], 1)], 1), _c('v-card', {
    staticClass: "mb-4",
    attrs: {
      "loading": _vm.loading,
      "disabled": _vm.loading
    }
  }, [_c('v-card-title', {
    staticClass: "primary white--text text-body-1 py-2"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-cash-register")]), _vm._v(" Billing ")], 1), _c('v-card-text', {
    staticClass: "mt-4"
  }, [_c('BillingStageSelector', {
    staticClass: "mb-4",
    attrs: {
      "value": _vm.job.billing_stage
    },
    on: {
      "change": _vm.updateBillingStage
    }
  }), _c('v-text-field', {
    attrs: {
      "hide-details": "",
      "outlined": "",
      "label": "Purchase Order Number",
      "value": _vm.job.purchase_order
    },
    on: {
      "change": function change(v) {
        return _vm.updateJob({
          purchase_order: v
        });
      }
    }
  })], 1)], 1), _c('v-card', {
    staticClass: "mt-4",
    attrs: {
      "loading": _vm.filesLoading,
      "disabled": _vm.filesLoading
    }
  }, [_c('v-card-title', {
    staticClass: "primary white--text text-body-1 py-0 pr-0"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-folder")]), _vm._v(" Files "), _c('v-spacer'), _c('FilePickerInbox', {
    on: {
      "pick": _vm.inboxFilePicked
    },
    scopedSlots: _vm._u([{
      key: "button",
      fn: function fn(_ref2) {
        var click = _ref2.click;
        return [_c('v-btn', {
          attrs: {
            "fab": "",
            "small": "",
            "text": "",
            "dark": ""
          },
          on: {
            "click": click
          }
        }, [_c('v-icon', [_vm._v("mdi-folder-network")])], 1)];
      }
    }])
  }), _c('v-btn', {
    attrs: {
      "fab": "",
      "small": "",
      "text": "",
      "dark": ""
    },
    on: {
      "click": function click() {
        return _vm.$refs.files.clickAdd();
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-cloud-upload")])], 1)], 1), _c('v-card-text', {
    staticClass: "pa-0 py-1"
  }, [_c('Files', {
    ref: "files",
    attrs: {
      "jobId": _vm.jobId
    }
  })], 1)], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "primary white--text text-body-1 py-0 pr-0"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-tape-measure")]), _vm._v(" Measurements "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "fab": "",
      "small": "",
      "text": "",
      "dark": ""
    },
    on: {
      "click": function click() {
        return _vm.$refs.measures.clickAdd();
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")])], 1)], 1), _c('v-card-text', {
    staticClass: "pa-0 py-1"
  }, [_c('Measures', {
    ref: "measures",
    attrs: {
      "jobId": _vm.jobId
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }