<template>
  <div>

    <v-dialog max-width="400px" scrollable v-model='showMenu'>
      <v-card>
        <v-card-title class='primary white--text'>Add a Measurement...</v-card-title>
        <v-card-text style="max-height: 50vh;">
          <v-list dense>
            <v-list-item v-for='(item, k) in menuItems' :key='k' link @click='clickItem(item)' dense>
              <v-list-item-content>
                <span v-if='item.menu' class='primary--text'>{{item.text}}</span>
                <span v-else >{{item.text}}</span>
              </v-list-item-content>
              <v-list-item-action v-if='item.menu'>
                <v-icon color='primary'>mdi-menu-right</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn text color='primary' @click='manualEntry'><v-icon>mdi-keyboard</v-icon></v-btn>
          <v-spacer />
          <v-btn text @click='showMenu = false'>Cancel</v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>

    <v-chip v-for='(tag, i) in tags' :key='i'>{{tag}}</v-chip>

  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>

<script>
import { /* mapGetters, */  mapActions } from 'vuex';
import YAML from 'js-yaml';
//import MenuItems from './MenuItems'

export default {
  name: 'title-selector',
  //components: { MenuItems },
  props: {
  },
  data(){
    return {
      loading: false,
      rootMenuItems: null,
      menuItems: [],
      showMenu: null,
      tags: [],
    }
  },
  computed: {
  },
  watch:{
    showMenu(show){ if(show == true) this.menuItems = this.rootMenuItems; }
  },
  methods: {
    ...mapActions("API", ["API"]),
    ...mapActions("UI", ["PROMPT"]),

    refresh(){
      this.loading = true;
      return this.API({ endpoint: 'measures/titles' })
      .then(config => {
        try{
          this.rootMenuItems = YAML.load(config);
        } catch(e){ this.rootMenuItems = []; }
        this.menuItems = this.rootMenuItems;
      })
      .finally(() => { this.loading = false; });
    },

    clickItem(item){
      if(item.menu) this.menuItems = item.menu;
      else{
        this.showMenu = false;
        this.$emit('input', item.text);
      }
    },

    manualEntry(){
      this.showMenu = false;
      this.PROMPT({ title: "Add a Measurement...", message: "Name for the measurement:" })
      .then(input => {
        if(input !== '') this.$emit('input', input);
      });
    },

    open(){
      this.showMenu = true;
    }

  },
  mounted(){
    this.refresh();
  }
}
</script>
