<template>
  <div>
    <v-menu
      v-model='open'
      :close-on-content-click="false"
      max-width="290"
    >
      <template #activator='{on,attrs}'>
        <v-text-field
          outlined hide-details
          :prepend-icon='$attrs.icon'
          :placeholder='$attrs.placeholder'
          :label='$attrs.label'
          readonly :value='formattedDate'
          :append-icon='date?"mdi-close":null' @click:append="clear"
          v-bind='attrs' v-on='on'
          @input='e=>$emit("change", e)'
        />
      </template>
      <v-date-picker v-model='date' @change='open = false' @input='e=>$emit("change", e)' />
    </v-menu>

  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>

<script>
import Moment from 'moment';
import { /* mapGetters, */  mapActions } from 'vuex';
export default {
  name: 'CalendarInput',
  components: {},
  props: {
    value: { type: String, default: null },
  },
  data(){
    return {
      open: false,
      date: null,
    }
  },
  computed: {
    formattedDate(){ return this.date ? Moment(this.date).format("MMM DD, yyyy") : ''; },
  },
  watch: {
    value(){ this.refreshValue(); }
  },
  methods: {
    ...mapActions("UI", ["CONFIRM"]),
    refreshValue(){
      this.date = this.value;
    },
    clear(){
      this.CONFIRM({ title: "Confirm", message: "Are you sure you want to clear this date?" })
      .then(()=>{
        this.date = null;
        this.$emit("change", null);
      });
    }
  },
  mounted(){ this.refreshValue(); }
}
</script>
