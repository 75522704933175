<template>
  <div>
    
    <slot
      name='button'
      v-bind="{ click: buttonClick}"
      v-if="PERMS.includes('FILEBOX')"
    />

    <v-dialog v-model="isOpen" max-width="1000">
      <v-card :disabled="loading" :loading="loading">
        <v-card-title class="primary white--text">Select File</v-card-title>
        <v-card-text>
          <v-data-table
              dense
              :items-per-page="-1" hide-default-footer
              class='dataTable mt-4'
              :items='files' v-model='selected'
              item-key='xid'
              :headers='headers'
              no-data-text="No Files"
              show-select
            >
              <template v-slot:item.file="{item}">
                <div class='d-flex align-center clickFile'>
                  <v-icon :color='ext(item.file).colour' class='mr-2' size='28'>{{ext(item.file).icon}}</v-icon>
                  <div>{{item.file.filename}}</div>
                </div>
              </template>
              <template v-slot:item.created_at="{item}">
                <v-tooltip bottom>
                  <template v-slot:activator='{on,attrs}'>
                    <span class='font-italic text-caption' v-on='on' v-bind='attrs'>{{moment(item.created_at).from()}}</span>
                  </template>
                  <span>{{moment(item.created_at).format('MMM D, Y')}}</span>
                </v-tooltip>
              </template>
              <template #item.actions="{item}">
                <v-btn fab x-small text @click='open_new_tab(item.file)' class="primary--text"><v-icon>mdi-open-in-new</v-icon></v-btn>
              </template>
            </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class='secondary' @click='isOpen=false'>Close</v-btn>
          <v-btn class='primary' @click='selectFiles' :disabled='!selected.length'>Select ({{selected.length}})</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<style lang="scss" scoped>
</style>

<script>
import { mapActions, mapGetters } from 'vuex';
import Moment from 'moment';
import FileTypes from '@/components/Common/FileTypes'
export default {
  name: '',
  components: {},

  props: {},

  data(){
    return {
      isOpen: false,
      loading: false,
      headers: [
      { text: "File", value: 'file' },
      { text: "Description", value: 'subject' },
      { text: "From", value: 'from' },
      { text: "Received", value: 'created_at' },
      { text: "", value: 'actions', align: 'right' },
    ],
      files: [],
      selected: [],
      extensions: FileTypes,
    }
  },

  computed: {
    ...mapGetters("AUTH", ["PERMS"]),
  },

  methods: {
    ...mapActions('API', ['API']),

    moment(m){ return new Moment(m); },
    
    buttonClick(){
      this.selected = [];
      this.files = [];
      this.refresh();
      this.isOpen = true;
    },

    refresh(){
      this.loading = true;
      this.API({ endpoint: 'filebox' })
      .then(files => { this.files = files; })
      .finally(() => { this.loading = false; })
    },

    ext(file){
      let filename = file.filename ?? "";
      let file_ext = this.fileExtension(filename).toLowerCase();
      return this.extensions[file_ext] ?? this.extensions._default;
    },
    
    fileExtension(filename){
      if(filename.lastIndexOf('.') != -1)
        return filename.split('.').slice(-1)[0];
      return "";
    },

    open_new_tab(file){
      window.open(`${window.app.config.API}/filebox/files/${file.xid}`, '_blank');
    },

    selectFiles(){
      this.isOpen = false;
      this.$emit('pick', this.selected);
    }

  },
}
</script>
