var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "elevation": "0",
      "disabled": _vm.loading,
      "tile": ""
    }
  }, [_vm.$attrs.hideAddButton !== "" ? _c('v-row', {
    staticClass: "mt-1"
  }, [_c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "text-right py-0",
    attrs: {
      "flat": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "small": "",
      "text": "",
      "color": "primary"
    },
    on: {
      "click": _vm.clickNewNote
    }
  }, [_vm._v("New Note")])], 1)], 1)], 1) : _vm._e(), _c('v-row', {
    ref: "sortable",
    staticClass: "py-2"
  }, [!_vm.notes.length ? _c('v-col', {
    staticClass: "text-center secondary-text text-caption",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" This " + _vm._s(_vm.parentType) + " has no notes. ")]) : _vm._e(), _vm._l(_vm.notes, function (n) {
    return _c('v-col', {
      key: n.xid,
      staticClass: "py-2",
      attrs: {
        "cols": "12",
        "sortable-id": n.xid
      }
    }, [_c('v-alert', {
      staticClass: "pl-4 py-0 pr-0 mb-0",
      staticStyle: {
        "background-color": "#F7F7F7!important"
      },
      attrs: {
        "colored-border": "",
        "border": "left",
        "color": n.colour,
        "elevation": "1"
      }
    }, [_c('div', {
      staticClass: "pa-0 text-body-1 d-flex align-start"
    }, [_c('v-icon', {
      staticClass: "mr-2 drag mt-2",
      attrs: {
        "color": n.colour
      }
    }, [_vm._v("mdi-drag")]), _c('span', {
      staticClass: "font-weight-medium mt-2"
    }, [_vm._v(" " + _vm._s(n.title) + " "), _c('v-tooltip', {
      attrs: {
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var on = _ref.on,
            attrs = _ref.attrs;
          return [_vm.PERMS.includes('SENSITIVE') ? _c('v-icon', _vm._g(_vm._b({
            attrs: {
              "color": n.sensitive ? "primary" : "secondary lighten-4"
            },
            on: {
              "click": function click($event) {
                return _vm.toggleSensitive(n);
              }
            }
          }, 'v-icon', attrs, false), on), [_vm._v("mdi-security")]) : _vm._e()];
        }
      }], null, true)
    }, [_vm._v(" Sensitive ")]), n.edited_by ? _c('span', {
      staticClass: "text-caption secondary--text2 font-italic d-inline-block"
    }, [_c('v-tooltip', {
      attrs: {
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref2) {
          var on = _ref2.on,
            attrs = _ref2.attrs;
          return [_c('span', _vm._g(_vm._b({}, 'span', attrs, false), on), [_vm._v(_vm._s(_vm.timeAgo(n.edited_at)))])];
        }
      }], null, true)
    }, [_c('span', {
      staticClass: "font-italic text-caption"
    }, [_vm._v(" " + _vm._s(_vm.localTimestamp(n.edited_at).format('MMM D, Y [at] LT')) + " ")])]), _vm._v(" - " + _vm._s(n.edited_by.name_first) + " " + _vm._s(n.edited_by.name_last))], 1) : _vm._e()], 1), _c('v-spacer'), _c('v-menu', {
      attrs: {
        "offset-y": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref3) {
          var on = _ref3.on,
            attrs = _ref3.attrs;
          return [_c('v-btn', _vm._g(_vm._b({
            staticClass: "pt-0",
            attrs: {
              "fab": "",
              "small": "",
              "text": ""
            },
            on: {
              "click": function click($event) {
                $event.stopPropagation();
                $event.preventDefault();
                return function () {}.apply(null, arguments);
              }
            }
          }, 'v-btn', attrs, false), on), [_c('v-icon', {
            attrs: {
              "color": n.colour
            }
          }, [_vm._v("mdi-dots-vertical")])], 1)];
        }
      }], null, true)
    }, [_c('v-list', [_c('v-list-item', {
      attrs: {
        "link": ""
      },
      on: {
        "click": function click($event) {
          return _vm.clickEdit(n);
        }
      }
    }, [_c('v-icon', {
      staticClass: "primary--text mr-2"
    }, [_vm._v("mdi-pencil")]), _vm._v(" Edit")], 1), _c('v-divider'), _c('v-list-item', {
      attrs: {
        "link": ""
      },
      on: {
        "click": function click($event) {
          return _vm.clickDelete(n);
        }
      }
    }, [_c('v-icon', {
      staticClass: "red--text mr-2"
    }, [_vm._v("mdi-trash-can")]), _vm._v(" Delete")], 1)], 1)], 1)], 1), n.note ? _c('div', {
      staticClass: "text-body-2 px-8 pb-2 secondary--text",
      staticStyle: {
        "white-space": "pre-wrap"
      }
    }, [_vm._v(_vm._s(n.note))]) : _vm._e()])], 1);
  })], 2), _c('v-dialog', {
    attrs: {
      "scrollable": "",
      "persistent": "",
      "value": _vm.dialog.show,
      "max-width": "600"
    }
  }, [_c('v-card', {
    staticStyle: {
      "max-height": "100vh",
      "overflow": "auto"
    },
    attrs: {
      "loading": _vm.dialog.loading,
      "disabled": _vm.dialog.loading
    }
  }, [_c('v-card-title', {
    staticClass: "primary white--text"
  }, [_vm._v("Note")]), _c('v-card-text', {
    staticClass: "pt-5"
  }, [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submitDialog.apply(null, arguments);
      }
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.dialog.title,
      "outlined": "",
      "label": "Title",
      "hide-details": ""
    },
    on: {
      "change": function change(v) {
        return _vm.dialog.title = v;
      }
    }
  })], 1), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-menu', {
    attrs: {
      "offset-y": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref4) {
        var on = _ref4.on,
          attrs = _ref4.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "mt-2",
          attrs: {
            "fab": "",
            "small": "",
            "color": _vm.dialog.colour
          }
        }, 'v-btn', attrs, false), on))];
      }
    }])
  }, [_c('v-list', _vm._l(["primary", "warning", "error", "success"], function (c) {
    return _c('span', {
      key: c
    }, [_c('v-list-item', {
      attrs: {
        "link": ""
      },
      on: {
        "click": function click($event) {
          _vm.dialog.colour = c;
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "color": c
      }
    }, [_vm._v("mdi-circle")])], 1)], 1);
  }), 0)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    attrs: {
      "value": _vm.dialog.note,
      "auto-grow": "",
      "outlined": "",
      "label": "Note",
      "hide-details": ""
    },
    on: {
      "change": function change(v) {
        return _vm.dialog.note = v;
      }
    }
  })], 1)], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', {}, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "large": "",
      "color": "secondary float-right",
      "width": "150"
    },
    on: {
      "click": function click($event) {
        _vm.dialog.show = false;
      }
    }
  }, [_vm._v("Cancel")]), _c('v-btn', {
    attrs: {
      "large": "",
      "color": "primary float-right",
      "width": "150"
    },
    on: {
      "click": _vm.submitDialog
    }
  }, [_vm._v(_vm._s(_vm.dialog.mode))])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }