<template>
  <div>

    <v-input hide-details @click='clickAddress'>
      <template #prepend>
        <v-icon class='mt-2 primary--text' @click='clickIcon'>mdi-google-maps</v-icon>
      </template>
        <v-textarea
          hide-details outlined auto-grow readonly
          class='pa-0 addressCard' :rows='1' label='Address'
          :value='addressText'
        />
    </v-input>


    <!-- DIALOG -->
    <v-dialog scrollable persistent
      :value='dialog.show'
      max-width='600'
    >
      <v-card :loading='dialog.loading' :disabled='dialog.loading' style='max-height:100vh;overflow:auto;'>
        <v-form @submit.prevent='submitDialog'>
          <v-card-title class='primary white--text'>Address Details</v-card-title>
          <v-card-text class='pt-5' style='max-height:500px; overflow-y: auto;'>
            <v-row>
              <v-col cols=12 v-if='customer'>
                <v-menu>
                  <template #activator="{on, attrs}">
                    <v-btn small class='primary' v-on="on" v-bind="attrs" @click='loadAddressBook'>Address Book</v-btn>
                  </template>
                  <v-list>
                    <v-list-item v-if='!addressBookLoaded'>Loading...</v-list-item>
                    <v-list-item link v-for='a in addressBook' :key='a.xid' @click='fillAddress(a)'>{{a.label}}</v-list-item>
                    <v-list-item v-if='addressBookLoaded && !addressBook.length'>No Addresses</v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
              <v-col cols=12><v-text-field v-model='dialog.line_1' outlined label='Address' hide-details /></v-col>
              <v-col cols=12><v-text-field v-model='dialog.line_2' outlined label='Address' hide-details /></v-col>
              <v-col cols=12><v-text-field v-model='dialog.line_3' outlined label='Address' hide-details /></v-col>
              <v-col cols=12 md=6><v-text-field v-model='dialog.suburb' outlined label='Locality' hide-details /></v-col>
              <v-col cols=12 md=3><v-text-field v-model='dialog.state' maxlength=3 outlined label='State' hide-details /></v-col>
              <v-col cols=12 md=3><v-text-field v-model='dialog.postcode' maxlength=5 outlined label='Postcode' hide-details /></v-col>
              <v-col cols=12 md=6><v-text-field v-model='dialog.country' outlined label='Country' hide-details /></v-col>
            </v-row>
          </v-card-text>
          <v-divider/>
          <v-card-actions class=''>
            <v-spacer />
            <v-btn large color='secondary float-right' width='150' @click='dialog.show=false'>Cancel</v-btn>
            <v-btn large color='primary float-right' width='150' type='submit'>Update</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.addressCard::v-deep{
  cursor: pointer;
  border-color: #00000061;
  &:hover{ border-color: black; }
  .v-text-field__slot{ cursor: pointer }
  textarea{ cursor: pointer; margin-bottom: 10px; }
}


</style>

<script>
import { /* mapGetters, */  mapActions } from 'vuex';
export default {
  name: 'Address',
  components: {},
  props: {
    value: { type: Object, required: false, default: null },
    customer: { type: Object, required: false, default: null },
  },
  watch: {
    value(v){
      if(v) this.address = { ...this.address, ...v };
      else Object.keys(this.address).forEach(k => { this.address[k] = ''; });
    }
  },
  data(){
    return {
      address: {
        line_1: '', line_2: '', line_3: '',
        suburb: '', state: '', postcode: '', country: ''
      },
      dialog: {
        loading: false, show: false,
        line_1: '', line_2: '', line_3: '',
        suburb: '', state: '', postcode: '', country: ''
      },
      addressBook: [],
      addressBookLoaded: false,
    }
  },
  computed: {
    addressText(){
      let { line_1, line_2, line_3, suburb, state, postcode, country } = this.address;
      let parts = [line_1, line_2, line_3, `${suburb} ${state} ${postcode}`.trim(), country];
      return parts.join('\n').trim().replace(/\n\s*\n/g, '\n');
    }
  },
  methods: {
    ...mapActions("API", ["API"]),

    clickIcon(){
      let base = "https://www.google.com/maps/search/?api=1&query=";
      let a = this.address;
      let q = encodeURIComponent([
        a.line_1, a.line_2, a.line_3, a.suburb,
        a.state, a.postcode, a.country
      ].join(', '));
      window.open(`${base}${q}`, '_blank');
    },

    clickAddress(){
      this.dialog = {
        ...this.dialog,
        show: true,
        ...this.address
      };
    },

    submitDialog(){
      let { line_1, line_2, line_3, suburb, state, postcode, country } = this.dialog;
      let newAddress = { line_1, line_2, line_3, suburb, state, postcode, country };
      this.dialog.show = false;
      this.$emit('change', newAddress);
    },

    loadAddressBook(){
      if(this.addressBookLoaded) return;
      this.API({ endpoint: `customers/${this.customer.xid}/addresses` })
      .then(addresses => {
        this.addressBook = addresses;
        this.addressBookLoaded = true;
      });
    },

    fillAddress(address){
      let { line_1, line_2, line_3, suburb, state, postcode, country } = address;
      this.dialog =  { ...this.dialog, line_1, line_2, line_3, suburb, state, postcode, country };
    }

  },
  created(){}
}
</script>
