var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "pr-4",
    attrs: {
      "flat": "",
      "tile": "",
      "loading": _vm.loading,
      "disabled": _vm.loading
    }
  }, [_c('ImageViewer', {
    ref: "image_viewer"
  }), _c('v-list', {
    ref: "sortable",
    attrs: {
      "dense": ""
    }
  }, [!_vm.files.length ? _c('v-list-item', [_c('span', {
    staticClass: "text-caption font-italic"
  }, [_vm._v("Job has no files.")])]) : _vm._l(_vm.files, function (f) {
    return _c('v-list-item', {
      key: f.xid,
      staticClass: "px-0",
      attrs: {
        "sortable-id": f.xid
      }
    }, [_c('v-list-item-icon', {
      staticClass: "mr-2"
    }, [_c('v-icon', {
      staticClass: "drag ml-1 mr-2"
    }, [_vm._v("mdi-drag")]), _c('v-icon', {
      staticClass: "mx-0",
      attrs: {
        "color": _vm.ext(f).colour
      }
    }, [_vm._v(_vm._s(_vm.ext(f).icon))])], 1), _c('v-list-item-content', {
      staticClass: "pointer",
      on: {
        "click": function click($event) {
          return _vm.clickFile(f);
        }
      }
    }, [_c('v-list-item-title', {
      attrs: {
        "title": f.filename
      }
    }, [_vm._v(" " + _vm._s(f.filename) + " ")]), _c('v-list-item-subtitle', {
      attrs: {
        "title": f.filename
      }
    }, [_vm._v(" " + _vm._s(_vm.bytesText(f.bytes)) + " "), f.hasMarkup ? _c('v-icon', {
      attrs: {
        "small": "",
        "color": "primary"
      }
    }, [_vm._v("mdi-image-edit-outline")]) : _vm._e()], 1)], 1), _c('div', [_c('v-tooltip', {
      attrs: {
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var on = _ref.on,
            attrs = _ref.attrs;
          return [_vm.PERMS.includes('SENSITIVE') ? _c('v-btn', _vm._g(_vm._b({
            attrs: {
              "small": "",
              "fab": "",
              "text": ""
            },
            on: {
              "click": function click($event) {
                return _vm.toggleSensitive(f);
              }
            }
          }, 'v-btn', attrs, false), on), [_c('v-icon', {
            attrs: {
              "color": f.options.sensitive ? "primary" : "secondary lighten-4"
            }
          }, [_vm._v("mdi-security")])], 1) : _vm._e()];
        }
      }], null, true)
    }, [_vm._v(" Sensitive ")]), _c('v-menu', {
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref2) {
          var on = _ref2.on,
            attrs = _ref2.attrs;
          return [_c('v-btn', _vm._g(_vm._b({
            attrs: {
              "small": "",
              "fab": "",
              "text": ""
            }
          }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-dots-horizontal")])], 1)];
        }
      }], null, true)
    }, [_c('v-list', {
      attrs: {
        "dense": ""
      }
    }, [_c('v-list-item', {
      attrs: {
        "link": ""
      },
      on: {
        "click": function click($event) {
          return _vm.downloadFile(f);
        }
      }
    }, [_c('v-icon', {
      staticClass: "mr-4",
      attrs: {
        "color": "primary"
      }
    }, [_vm._v("mdi-download")]), _vm._v("Download File ")], 1), _vm.ext(f).action == "image_viewer" ? _c('v-list-item', {
      attrs: {
        "link": ""
      },
      on: {
        "click": function click($event) {
          return _vm.printImage(f);
        }
      }
    }, [_c('v-icon', {
      staticClass: "mr-4",
      attrs: {
        "color": "primary"
      }
    }, [_vm._v("mdi-printer")]), _vm._v("Print ")], 1) : _vm._e(), _c('v-list-item', {
      attrs: {
        "link": ""
      },
      on: {
        "click": function click($event) {
          return _vm.renameFile(f);
        }
      }
    }, [_c('v-icon', {
      staticClass: "mr-4",
      attrs: {
        "color": "primary"
      }
    }, [_vm._v("mdi-rename-box")]), _vm._v("Rename ")], 1), _c('v-divider'), f.hasMarkup ? _c('v-list-item', {
      attrs: {
        "link": ""
      },
      on: {
        "click": function click($event) {
          return _vm.deleteFileMarkup(f);
        }
      }
    }, [_c('v-icon', {
      staticClass: "mr-4",
      attrs: {
        "color": "red"
      }
    }, [_vm._v("mdi-pencil-off")]), _vm._v("Clear Markup ")], 1) : _vm._e(), _c('v-list-item', {
      attrs: {
        "link": ""
      },
      on: {
        "click": function click($event) {
          return _vm.deleteFile(f);
        }
      }
    }, [_c('v-icon', {
      staticClass: "mr-4",
      attrs: {
        "color": "red"
      }
    }, [_vm._v("mdi-trash-can")]), _vm._v("Delete ")], 1)], 1)], 1)], 1)], 1);
  })], 2), _c('div', [_c('input', {
    ref: "upload",
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "type": "file",
      "multiple": ""
    },
    on: {
      "input": _vm.filesAdded
    }
  }), _c('v-dialog', {
    attrs: {
      "value": _vm.uploader.show,
      "persistent": "",
      "scrollable": "",
      "max-width": "500px"
    }
  }, [_c('v-card', {
    staticStyle: {
      "max-height": "350px"
    }
  }, [_c('v-card-title', {
    staticClass: "primary white--text"
  }, [_vm._v("Uploading Files")]), _c('v-divider'), _c('v-card-text', {
    staticClass: "pt-4 pb-0"
  }, [_c('div', {
    staticClass: "text-center py-2 primary--text"
  }, [_vm.uploader.queue.length > 0 ? _c('span', [_vm._v("Uploading " + _vm._s(_vm.uploader.queue.length) + " files...")]) : _c('span', [_vm._v("Upload complete.")])]), _c('v-list', {
    attrs: {
      "dense": ""
    }
  }, _vm._l(_vm.uploader.queue, function (f, i) {
    return _c('v-list-item', {
      key: f.filename
    }, [_c('v-list-item-icon', [_c('v-icon', {
      attrs: {
        "color": _vm.ext(f).colour
      }
    }, [_vm._v(_vm._s(_vm.ext(f).icon))])], 1), _c('v-list-item-content', [_c('v-list-item-title', {
      class: {
        'primary--text': i == 0
      }
    }, [_vm._v(_vm._s(f.name))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.bytesText(f.size)))])], 1), i == 0 ? _c('div', [_c('v-progress-circular', {
      staticClass: "mr-2",
      attrs: {
        "value": _vm.uploader.progress,
        "indeterminate": _vm.uploader.progress == 100,
        "rotate": -90,
        "color": "primary",
        "size": "25"
      }
    })], 1) : _vm._e()], 1);
  }), 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "primary",
    attrs: {
      "loading": _vm.uploader.queue.length > 0,
      "disabled": _vm.uploader.queue.length > 0
    },
    on: {
      "click": function click($event) {
        _vm.uploader.show = false;
      }
    }
  }, [_vm._v("Done")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }