<template>
  <v-card class='pr-4' flat tile :loading='loading' :disabled='loading'>

    <TitleSelector ref='titleSelector' @input='newMeasurement' />
    <Viewer ref='viewer' @updated='refresh' />

    <v-list dense ref='sortable'>
      <v-list-item v-if='!measures.length'><span class='text-caption font-italic'>Job has no measurements.</span></v-list-item>
      <v-list-item v-else v-for='m in measures' :key='m.xid' :sortable-id='m.xid' class='px-0'>
        <v-list-item-icon class='mr-2'>
          <v-icon class='drag ml-1 mr-2'>mdi-drag</v-icon>
        </v-list-item-icon>
        <v-list-item-content @click='clickMeasure(m)' class='pointer'>
          <v-list-item-title>
            {{m.label}}
            <v-icon small v-if='m.hasFile' color='secondary' title='Picture'>mdi-image-outline</v-icon>
          </v-list-item-title>
          <v-list-item-subtitle>

          </v-list-item-subtitle>
        </v-list-item-content>
        <div>
          <v-menu>
            <template #activator='{on,attrs}'>
              <v-btn small fab text v-on='on' v-bind='attrs'>
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item link @click='printImage(m)' v-if='m.hasFile'>
                <v-icon class='mr-4' color='primary'>mdi-printer</v-icon>Print
              </v-list-item>
              <v-divider />
              <v-list-item link @click='deleteMeasure(m)'>
                <v-icon class='mr-4' color='red'>mdi-trash-can</v-icon>Delete
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-list-item>
    </v-list>

  </v-card>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.pointer{
  cursor: pointer;
  &:hover{
    color: #1976d2;
  }
}
.drag{ cursor: move; }
</style>

<script>
import { /* mapGetters, */  mapActions } from 'vuex';
import Sortable from 'sortablejs';
import TitleSelector from '@/components/Measures/TitleSelector'
import Viewer from '@/components/Measures/Viewer'
//import ImageViewer from '@/components/ImageViewer/Viewer'
export default {
  name: 'job-measures',
  components: { TitleSelector, Viewer },
  props: {
    jobId: { type: String, required: true, },
  },
  data(){
    return {
      loading: false,
      measures: [],
      sortable: null,
    }
  },
  computed: {},
  watch: {
  },
  methods: {
    ...mapActions("API", ["API"]),
    ...mapActions("UI", ["PROMPT", "CONFIRM", "ALERT"]),
    clickAdd(){
      this.$refs.titleSelector.open();
    },
    newMeasurement(label){
      this.doneLoading(false);
      let data = { label };
      this.API({ method: 'POST', endpoint: `jobs/${this.jobId}/measures`, data })
      .then(this.refresh)
      .finally(this.doneLoading)
    },
    clickMeasure(measure){
      this.$refs.viewer.open(measure.xid);
    },
    refresh(){
      this.doneLoading(false);
      return this.API({ endpoint: `jobs/${this.jobId}/measures` })
      .then(measures => { this.measures = measures; })
      .finally(this.doneLoading);
    },
    makeSortable(){
      this.sortable = new Sortable(this.$refs.sortable.$el, {
        animation: 150,
        dataIdAttr: 'sortable-id',
        forceFallback: true,
        handle: '.drag',
        dragClass: 'dragging',
        onEnd: this.sort,
      });
    },
    sort(sortEvent){
      if(sortEvent.oldIndex == sortEvent.newIndex) return;
      let data = { order: this.sortable.toArray() };
      this.loading = true;
      this.API({ method: 'POST', endpoint: `jobs/${this.jobId}/measures/sort`, data })
      .finally(() => { this.loading = false; });
    },
    deleteMeasure(measure){
      this.CONFIRM({ title: 'Delete Measure', message:'All data in this measurement will be lost. Continue?' })
      .then(() => {
        this.doneLoading(false);
        this.API({ method: 'DELETE', endpoint: `measures/${measure.xid}` })
        .then(this.refresh).finally(this.doneLoading);
      });
    },
    doneLoading(done = true){ this.loading = !done; },

    printImage(measure){
      console.log(measure);
      window.open(`${window.app.config.API}/measures/${measure.xid}/print_image`, '_blank');
    },

  },
  mounted(){
    this.refresh();
    this.makeSortable();
  }
}
</script>
