var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [false ? _c('span', [_c('b', [_vm._v("Search")]), _vm._v(" " + _vm._s(_vm.searchText) + ", "), _c('b', [_vm._v("Current")]), _vm._v(" " + _vm._s(_vm.optionSelected) + ", "), _c('b', [_vm._v("Results")]), _vm._v(" " + _vm._s(_vm.options))]) : _vm._e(), _c('v-autocomplete', {
    attrs: {
      "no-filter": "",
      "items": _vm.options,
      "item-text": "name",
      "item-value": "xid",
      "return-object": "",
      "loading": _vm.loading,
      "value": _vm.optionSelected,
      "clearable": "",
      "outlined": "",
      "color": "blue",
      "placeholder": "Add a customer...",
      "no-data-text": _vm.noDataText,
      "hide-details": "",
      "prepend-inner-icon": "mdi-account-group",
      "label": "Customer"
    },
    on: {
      "update:search-input": _vm.search,
      "change": _vm.change,
      "click:prepend-inner": _vm.clickIcon
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }