var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "pr-4",
    attrs: {
      "flat": "",
      "tile": "",
      "loading": _vm.loading,
      "disabled": _vm.loading
    }
  }, [_c('TitleSelector', {
    ref: "titleSelector",
    on: {
      "input": _vm.newMeasurement
    }
  }), _c('Viewer', {
    ref: "viewer",
    on: {
      "updated": _vm.refresh
    }
  }), _c('v-list', {
    ref: "sortable",
    attrs: {
      "dense": ""
    }
  }, [!_vm.measures.length ? _c('v-list-item', [_c('span', {
    staticClass: "text-caption font-italic"
  }, [_vm._v("Job has no measurements.")])]) : _vm._l(_vm.measures, function (m) {
    return _c('v-list-item', {
      key: m.xid,
      staticClass: "px-0",
      attrs: {
        "sortable-id": m.xid
      }
    }, [_c('v-list-item-icon', {
      staticClass: "mr-2"
    }, [_c('v-icon', {
      staticClass: "drag ml-1 mr-2"
    }, [_vm._v("mdi-drag")])], 1), _c('v-list-item-content', {
      staticClass: "pointer",
      on: {
        "click": function click($event) {
          return _vm.clickMeasure(m);
        }
      }
    }, [_c('v-list-item-title', [_vm._v(" " + _vm._s(m.label) + " "), m.hasFile ? _c('v-icon', {
      attrs: {
        "small": "",
        "color": "secondary",
        "title": "Picture"
      }
    }, [_vm._v("mdi-image-outline")]) : _vm._e()], 1), _c('v-list-item-subtitle')], 1), _c('div', [_c('v-menu', {
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var on = _ref.on,
            attrs = _ref.attrs;
          return [_c('v-btn', _vm._g(_vm._b({
            attrs: {
              "small": "",
              "fab": "",
              "text": ""
            }
          }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-dots-horizontal")])], 1)];
        }
      }], null, true)
    }, [_c('v-list', {
      attrs: {
        "dense": ""
      }
    }, [m.hasFile ? _c('v-list-item', {
      attrs: {
        "link": ""
      },
      on: {
        "click": function click($event) {
          return _vm.printImage(m);
        }
      }
    }, [_c('v-icon', {
      staticClass: "mr-4",
      attrs: {
        "color": "primary"
      }
    }, [_vm._v("mdi-printer")]), _vm._v("Print ")], 1) : _vm._e(), _c('v-divider'), _c('v-list-item', {
      attrs: {
        "link": ""
      },
      on: {
        "click": function click($event) {
          return _vm.deleteMeasure(m);
        }
      }
    }, [_c('v-icon', {
      staticClass: "mr-4",
      attrs: {
        "color": "red"
      }
    }, [_vm._v("mdi-trash-can")]), _vm._v("Delete ")], 1)], 1)], 1)], 1)], 1);
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }