var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-autocomplete', {
    attrs: {
      "items": _vm.JOB_BILLING_STAGES,
      "item-text": "label",
      "item-value": "xid",
      "return-object": "",
      "value": _vm.optionSelected,
      "clearable": "",
      "outlined": "",
      "color": "blue",
      "label": "Billing Status",
      "hide-details": "",
      "xclick:prepend": "clickIcon"
    },
    on: {
      "change": _vm.change
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }