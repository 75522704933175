<template>
  <v-card elevation=0 :disabled='loading' tile>
    <v-row class='mt-1' v-if='$attrs.hideAddButton !== ""'>
      <v-col cols=12 class='pb-0'>
        <v-card flat class='text-right py-0'>
          <v-btn small text color='primary' @click='clickNewNote'>New Note</v-btn>
        </v-card>
      </v-col>
    </v-row>

    <v-row ref='sortable' class='py-2'>

      <v-col cols=12 v-if='!notes.length' class='text-center secondary-text text-caption'>
        This {{parentType}} has no notes.
      </v-col>

      <v-col cols=12 v-for='n in notes' v-bind:key='n.xid' :sortable-id='n.xid' class='py-2'>
        <v-alert
           colored-border border='left' :color='n.colour' elevation=1
           class='pl-4 py-0 pr-0 mb-0'
           style='background-color:#F7F7F7!important;'
        >

        <div class='pa-0 text-body-1 d-flex align-start'>

          <v-icon :color='n.colour' class='mr-2 drag mt-2'>mdi-drag</v-icon>

          <span class='font-weight-medium mt-2'>
            {{n.title}}
            <v-tooltip bottom>
              <template #activator="{on,attrs}">
                  <v-icon v-on="on" v-bind="attrs" v-if="PERMS.includes('SENSITIVE')" :color='n.sensitive?"primary":"secondary lighten-4"' @click="toggleSensitive(n)">mdi-security</v-icon>
              </template>
              Sensitive
            </v-tooltip>
            <span v-if='n.edited_by' class='text-caption secondary--text2 font-italic d-inline-block'>
              <v-tooltip bottom>
                <template v-slot:activator='{on,attrs}'>
                  <span v-on='on' v-bind='attrs'>{{timeAgo(n.edited_at)}}</span>
                </template>
                <span class='font-italic text-caption'>
                  {{localTimestamp(n.edited_at).format('MMM D, Y [at] LT')}}
                </span>
              </v-tooltip>
            - {{n.edited_by.name_first}} {{n.edited_by.name_last}}</span>
          </span>

          <v-spacer />

          <v-menu offset-y>
            <template v-slot:activator="{on,attrs}">
              <v-btn class='pt-0' fab small text v-on="on" v-bind="attrs" @click.stop.prevent="()=>{}"><v-icon :color='n.colour'>mdi-dots-vertical</v-icon></v-btn>
            </template>
            <v-list>
              <v-list-item link @click='clickEdit(n)'><v-icon class='primary--text mr-2'>mdi-pencil</v-icon> Edit</v-list-item>
              <v-divider></v-divider>
              <v-list-item link @click='clickDelete(n)'><v-icon class='red--text mr-2'>mdi-trash-can</v-icon> Delete</v-list-item>
            </v-list>
          </v-menu>

        </div>

        <div v-if='n.note' class='text-body-2 px-8 pb-2 secondary--text' style='white-space:pre-wrap;'>{{n.note}}</div>
        </v-alert>
      </v-col>

    </v-row>




    <v-dialog scrollable persistent
      :value='dialog.show'
      max-width='600'
    >
      <v-card :loading='dialog.loading' :disabled='dialog.loading' style='max-height:100vh;overflow:auto;'>

          <v-card-title class='primary white--text'>Note</v-card-title>
          <v-card-text class='pt-5'>
            <v-form @submit.prevent='submitDialog'>
              <v-row>
                <v-col cols=10><v-text-field :value='dialog.title' @change='(v)=>dialog.title=v' outlined label='Title' hide-details /></v-col>
                <v-col cols=2 class='text-center'>
                  <v-menu offset-y>
                    <template v-slot:activator="{on,attrs}">
                      <v-btn v-on='on' v-bind='attrs'
                        fab small :color='dialog.colour' class='mt-2'
                      ></v-btn>
                    </template>
                    <v-list>
                      <span v-for='c in ["primary", "warning", "error", "success"]' :key='c'>
                        <v-list-item  link @click='dialog.colour=c'><v-icon :color='c'>mdi-circle</v-icon></v-list-item>
                      </span>
                    </v-list>
                  </v-menu>
                </v-col>
                <v-col cols=12><v-textarea :value='dialog.note' @change='(v)=>dialog.note=v' auto-grow outlined label='Note' hide-details /></v-col>
              </v-row>
            </v-form>

          </v-card-text>
          <v-divider/>
          <v-card-actions class=''>
            <v-spacer />
            <v-btn large color='secondary float-right' width='150' @click='dialog.show=false'>Cancel</v-btn>
            <v-btn large color='primary float-right' width='150' @click='submitDialog'>{{dialog.mode}}</v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>

  </v-card>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.drag{ cursor: move; }
</style>

<script>
import Sortable from 'sortablejs';
import Moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'Notes',
  components: {},
  props: {
    'parentType': { type: String, required: true, },
    'parentUrl': { type: String, required: true, },
  },
  data(){ return {
      loading: false,
      notes: [],
      sortable: null,
      sorting: false,
      //forceFallback: true,
      dialog: {
        show: false, loading: false, mode: '',
        title: '', note: '', colour: 'primary',
      },
  }},
  computed: {
    ...mapGetters("AUTH", ["PERMS"]),
  },
  methods: {
    ...mapActions("API", ["API"]),
    ...mapActions("UI", ["CONFIRM"]),

    moment(m){ return new Moment(m); },

    refreshNotes(){
      this.loading = true;
      this.API({ method: 'GET', endpoint: `${this.parentUrl}/notes` })
      .then(notes => { this.notes = notes; })
      .finally(() => { this.loading = false; });
    },

    clickNewNote(){
      this.dialog = {
        ...this.dialog,
        show: true, loading: false, mode: 'create',
        title: '', note: '', colour: 'primary',
      };
    },

    submitDialog(){
      if(this.dialog.mode == 'create') this.createNote();
      if(this.dialog.mode == 'update') this.updateNote();
    },

    createNote(){
      this.dialog.loading = true;
      let { title, note, colour } = this.dialog;
      let data = { title, note, colour };
      this.API({ method: 'POST', endpoint: `${this.parentUrl}/notes/create`, data })
      .then(() => {
        this.dialog.show = false;
        this.refreshNotes();
      })
      .finally(() => { this.dialog.loading = false; });
    },

    makeSortable(){
      this.sortable = new Sortable(this.$refs.sortable, {
        animation: 150,
        dataIdAttr: 'sortable-id',
        forceFallback: true,
        handle: '.drag',
        dragClass: 'dragging',
        onEnd: this.sort,
      });
    },

    sort(sortEvent){
      if(sortEvent.oldIndex == sortEvent.newIndex) return;
      let data = { order: this.sortable.toArray().filter(key => key != 'add') };
      this.sortable.sort(['add', ...data.order]);
      this.loading = true;
      this.API({ method: 'POST', endpoint: `notes/sort`, data })
      .finally(() => { this.loading = false; });
    },

    clickEdit(note){
      this.dialog = {
        ...this.dialog,
        ...note,
        show: true, loading: false, mode: 'update',
      };
    },

    updateNote(){
      this.dialog.loading = true;
      let { title, note, colour } = this.dialog;
      let data = { title, note, colour };
      this.API({ method: 'PATCH', endpoint: `notes/${this.dialog.xid}`, data })
      .then(() => {
        this.dialog.show = false;
        this.refreshNotes();
      })
      .finally(() => { this.dialog.loading = false; });
    },

    toggleSensitive(note){
      let sensitive = !note.sensitive;
      let data = { sensitive };
      this.loading = true;
      this.API({ method: 'PATCH', endpoint: `notes/${note.xid}`, data })
      .then(() => { note.sensitive = sensitive; })
      .finally(() => { this.loading = false; })
    },

    clickDelete(note){
      let {xid} = note;
      this.CONFIRM({
        title: "Delete Note?",
        message: `Are you sure you want to delete this note?`
      })
      .then(()=>{
        this.loading = true;
        this.API({ method: 'DELETE', endpoint: `notes/${xid}` })
        .then(this.refreshNotes)
        .finally(()=>{ this.loading = false; });
      });
    },

    timeAgo(t){
      return Moment().to(Moment.utc(t));
    },

    localTimestamp(t){
      return Moment.utc(t).local();
    },

  },
  mounted(){
    this.refreshNotes();
    this.makeSortable();
  }
}
</script>
