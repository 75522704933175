<template>
  <v-card class='pr-4' flat tile :loading='loading' :disabled='loading'>
    <ImageViewer ref='image_viewer' />
    <v-list dense ref='sortable'>
      <v-list-item v-if='!files.length'><span class='text-caption font-italic'>Job has no files.</span></v-list-item>
      <v-list-item v-else v-for='f in files' :key='f.xid' :sortable-id='f.xid' class='px-0'>
        <v-list-item-icon class='mr-2'>
          <v-icon class='drag ml-1 mr-2'>mdi-drag</v-icon>
          <v-icon :color='ext(f).colour' class='mx-0'>{{ext(f).icon}}</v-icon>
        </v-list-item-icon>
        <v-list-item-content @click='clickFile(f)' class='pointer'>
          <v-list-item-title :title='f.filename'>
            {{f.filename}}
          </v-list-item-title>
          <v-list-item-subtitle :title='f.filename'>
            {{bytesText(f.bytes)}}
            <v-icon small color='primary' v-if='f.hasMarkup'>mdi-image-edit-outline</v-icon>
          </v-list-item-subtitle>
        </v-list-item-content>
        <div>
          <v-tooltip bottom>
            <template #activator="{on,attrs}">
              <v-btn small fab text @click='toggleSensitive(f)' v-if="PERMS.includes('SENSITIVE')" v-on="on" v-bind="attrs">
                <v-icon :color='f.options.sensitive?"primary":"secondary lighten-4"'>mdi-security</v-icon>
              </v-btn>
            </template>
            Sensitive
          </v-tooltip>
          
          <v-menu>
            <template #activator='{on,attrs}'>
              <v-btn small fab text v-on='on' v-bind='attrs'>
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item link @click='downloadFile(f)'>
                <v-icon class='mr-4' color='primary'>mdi-download</v-icon>Download File
              </v-list-item>
              <v-list-item link @click='printImage(f)' v-if='ext(f).action=="image_viewer"'>
                <v-icon class='mr-4' color='primary'>mdi-printer</v-icon>Print
              </v-list-item>
              <v-list-item link @click='renameFile(f)'>
                <v-icon class='mr-4' color='primary'>mdi-rename-box</v-icon>Rename
              </v-list-item>
              <v-divider />
              <v-list-item link @click='deleteFileMarkup(f)' v-if='f.hasMarkup'>
                <v-icon class='mr-4' color='red'>mdi-pencil-off</v-icon>Clear Markup
              </v-list-item>
              <v-list-item link @click='deleteFile(f)'>
                <v-icon class='mr-4' color='red'>mdi-trash-can</v-icon>Delete
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-list-item>
    </v-list>

    <!-- FILE UPLOADER -->
    <div>
      <input type='file' multiple ref='upload' style='display:none;' @input='filesAdded' />

      <v-dialog :value='uploader.show' persistent scrollable max-width='500px' >
        <v-card style='max-height: 350px;'>
          <v-card-title class='primary white--text'>Uploading Files</v-card-title>
          <v-divider></v-divider>
          <v-card-text class='pt-4 pb-0'>
            <div class='text-center py-2 primary--text'>
              <span v-if='uploader.queue.length > 0'>Uploading {{uploader.queue.length}} files...</span>
              <span v-else>Upload complete.</span>
            </div>
            <v-list dense>
              <v-list-item v-for='(f,i) in uploader.queue' :key='f.filename'>
                <v-list-item-icon>
                  <v-icon :color='ext(f).colour'>{{ext(f).icon}}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title :class="{'primary--text':i==0}">{{f.name}}</v-list-item-title>
                  <v-list-item-subtitle>{{bytesText(f.size)}}</v-list-item-subtitle>
                </v-list-item-content>
                <div v-if='i==0'>
                  <v-progress-circular :value='uploader.progress' :indeterminate='uploader.progress==100' :rotate='-90' color='primary' size=25 class='mr-2' />
                </div>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer />
            <v-btn class='primary'
              @click='uploader.show = false'
              :loading='uploader.queue.length>0'
              :disabled='uploader.queue.length>0'
            >Done</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </div>

  </v-card>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.pointer{
  cursor: pointer;
  &:hover{
    color: #1976d2;
  }
}
.drag{ cursor: move; }
</style>

<script>
import { mapGetters,  mapActions } from 'vuex';
import Sortable from 'sortablejs';
import FileTypes from '@/components/Common/FileTypes'
import ImageViewer from '@/components/ImageViewer/Viewer'
export default {
  name: 'Files',
  components: { ImageViewer },
  props: {
    jobId: { type: String, required: true, },
  },
  data(){
    return {
      loading: false,
      files: [
      ],
      extensions: FileTypes,
      uploader: {
        show: false,
        uploading: false,
        queue: [],
        progress: 0,
      },
      sortable: null,
    }
  },
  computed: {
    ...mapGetters("AUTH", ["PERMS"]),
  },
  watch: {
    'uploader.queue': function(){ this.uploadNext(); },
    'uploader.uploading': function(){ this.uploadNext(); },
  },
  methods: {
    ...mapActions("API", ["API"]),
    ...mapActions("UI", ["PROMPT", "CONFIRM"]),
    ext(file){
      let filename = file.name ?? file.filename ?? "";
      let file_ext = this.fileExtension(filename).toLowerCase();
      return this.extensions[file_ext] ?? this.extensions._default;
    },
    fileExtension(filename){
      if(filename.lastIndexOf('.') != -1)
        return filename.split('.').slice(-1)[0];
      return "";
    },
    bytesText(bytes){
      let symbol = "B";
      if(bytes > 1024) { bytes /= 1024; symbol = 'KB' }
      if(bytes > 1024) { bytes /= 1024; symbol = 'MB' }
      if(bytes > 1024) { bytes /= 1024; symbol = 'GB' }
      return `${Math.floor(bytes)} ${symbol}`;
    },
    clickAdd(){
        this.$refs.upload.click();
    },
    filesAdded(){
      console.log("FILE ADDED", this.$refs.upload.files);
      [...this.$refs.upload.files].forEach(f => this.uploader.queue.push(f));
      this.$refs.upload.value = null;
    },
    clickFile(file){
      let action = this.ext(file).action;
      if(action == 'download') this.downloadFile(file);
      if(action == 'new_tab') this.open_new_tab(file);
      if(action == 'image_viewer') this.viewImage(file);
    },
    uploadNext(){
      if(this.uploader.uploading || this.uploader.queue.length == 0) return;
      this.uploader.show = true;
      this.uploader.uploading = true;
      this.uploader.progress = 0;
        let data = new FormData();
        data.append('file', this.uploader.queue[0]);
        this.API({ method: 'POST', endpoint: `jobs/${this.jobId}/files/upload`, data, progress: this.progress })
        .then(() => {
          this.refreshFiles();
          this.uploader.queue.splice(0,1);
          this.uploader.uploading = false;
        })
        .catch(() => { this.uploader.uploading = false; });
    },
    progress(p){
      this.uploader.progress = p;
    },
    async refreshFiles(){
      this.loading = true;
      return this.API({ endpoint: `jobs/${this.jobId}/files` })
      .then(files => { this.files = files; })
      .finally(() => { this.loading = false; })
    },
    renameFile(f){
      let baseName = f.filename;
      let lastDotIndex = baseName.lastIndexOf('.') ;
      if(lastDotIndex != -1) baseName = baseName.substring(0,lastDotIndex);
      this.PROMPT({ title: 'Rename File', message: 'New file name:', placeholder: baseName })
      .then(newName => {
        if(newName == "" || newName == baseName) return;
        let ext = this.fileExtension(f.filename);
        if(ext.length) newName = `${newName}.${ext}`;
        let data = { filename: newName };
        this.API({ method: 'PATCH', endpoint: `jobs/${this.jobId}/files/${f.xid}`, data })
        .finally(this.refreshFiles);
      });
    },
    downloadFile(file){
      //window.open(`${window.app.config.API}/jobs/${this.jobId}/files/${file.xid}/download`, '_blank');
      window.location.href = `${window.app.config.API}/jobs/${this.jobId}/files/${file.xid}/download`;
    },
    open_new_tab(file){
      window.open(`${window.app.config.API}/jobs/${this.jobId}/files/${file.xid}`, '_blank');
    },
    viewImage(file){
      console.log("View Image", file.xid);
      let src = `${window.app.config.API}/jobs/${this.jobId}/files/${file.xid}`;
      let saveMarkup = (markup) => {
        let r = this.API({ method: 'PATCH', endpoint: `jobs/${this.jobId}/files/${file.xid}`, data: { markup }});
        r.then(() => { this.refreshFiles(); })
        return r;
      };
      if(!file.hasMarkup){
        this.$refs.image_viewer.open(src, null, saveMarkup);
      }
      else{
        this.API({ endpoint: `jobs/${this.jobId}/files/${file.xid}/markup` })
        .then(markup => {
          this.$refs.image_viewer.open(src, markup, saveMarkup);
        });
      }
    },
    deleteFile(file){
      this.CONFIRM({ title: "Delete File", message: `Delete the file "${file.filename}"?`})
      .then(()=>{
        this.API({ method: 'DELETE', endpoint: `jobs/${this.jobId}/files/${file.xid}` })
        .then(this.refreshFiles);
      });
    },
    deleteFileMarkup(file){
      this.CONFIRM({ title: "Clear Markup", message: `Clear the markup from file "${file.filename}"?`})
      .then(()=>{
        this.API({ method: 'DELETE', endpoint: `jobs/${this.jobId}/files/${file.xid}/markup` })
        .then(this.refreshFiles);
      });
    },
    toggleSensitive(file){
      let sensitive = !file.options.sensitive;
      let data = { sensitive };
      this.loading = true;
      this.API({ method: 'PATCH', endpoint: `jobs/${this.jobId}/files/${file.xid}`, data })
      .then(() => { file.options.sensitive = sensitive; })
      .finally(() => { this.loading = false; })
      //this.API({ method: 'PATCH', endpoint: `jobs/${this.jobId}/files/${f.xid}`, data })
      //.finally(this.refreshFiles);
    },
    makeSortable(){
      this.sortable = new Sortable(this.$refs.sortable.$el, {
        animation: 150,
        dataIdAttr: 'sortable-id',
        forceFallback: true,
        handle: '.drag',
        dragClass: 'dragging',
        onEnd: this.sort,
      });
    },
    sort(sortEvent){
      if(sortEvent.oldIndex == sortEvent.newIndex) return;
      let data = { order: this.sortable.toArray() };
      this.loading = true;
      this.API({ method: 'POST', endpoint: `jobs/${this.jobId}/files/sort`, data })
      .finally(() => { this.loading = false; });
    },
    printImage(file){
      window.open(`${window.app.config.API}/jobs/${this.jobId}/files/${file.xid}/print_image`, '_blank');
    },

  },
  mounted(){
    this.refreshFiles();
    this.makeSortable();
  }
}
</script>
