var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "ImageViewer"
    }
  }, [_c('TitleSelector', {
    ref: "titleSelector",
    on: {
      "input": _vm.updateTitle
    }
  }), _vm.active ? _c('v-dialog', {
    attrs: {
      "value": _vm.active,
      "fullscreen": "",
      "transition": "dialog-bottom-transition"
    }
  }, [_c('v-container', {
    staticClass: "pt-14 pb-0",
    attrs: {
      "id": "container",
      "fill-height": "",
      "fluid": ""
    }
  }, [_vm.measure ? [_c('v-row', {
    staticStyle: {
      "height": "100%"
    }
  }, [_c('v-col', {
    ref: "toggleArea",
    staticClass: "xyellow",
    attrs: {
      "cols": _vm.viewer_fullscreen ? 12 : 8
    }
  }, [_vm.measure.file ? [_c('div', {
    staticStyle: {
      "position": "absolute",
      "top": "10px"
    }
  }, [_c('v-btn', {
    staticClass: "white--text",
    staticStyle: {
      "z-index": "10"
    },
    attrs: {
      "fab": "",
      "small": "",
      "text": ""
    },
    on: {
      "click": _vm.toggleFullscreen
    }
  }, [_c('v-icon', [_vm._v(_vm._s(_vm.viewer_fullscreen ? "mdi-fullscreen-exit" : "mdi-fullscreen"))])], 1)], 1), _vm.markup.ready ? _c('MarkupImage', {
    attrs: {
      "id": "MarkupImage",
      "src": _vm.markup.src,
      "srcMarkup": _vm.markup.srcMarkup,
      "save": _vm.markup.onSave
    }
  }) : _vm._e()] : _vm._e()], 2), !_vm.viewer_fullscreen ? _c('v-col', {
    staticStyle: {
      "height": "100%",
      "overflow-y": "scroll"
    },
    attrs: {
      "cols": 4
    }
  }, [_c('v-card', {
    attrs: {
      "loading": _vm.loading,
      "disabled": _vm.loading
    }
  }, [_c('v-card-title', {
    staticClass: "primary white--text py-2"
  }, [_vm._v(" " + _vm._s(_vm.measure.label) + " "), _c('v-spacer'), _c('v-btn', {
    staticClass: "white--text",
    attrs: {
      "fab": "",
      "x-small": "",
      "text": ""
    },
    on: {
      "click": _vm.changeTitle
    }
  }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1)], 1), _c('v-card-text', {
    staticClass: "pa-4"
  }, [_c('MeasureTags', {
    attrs: {
      "tagdata": _vm.measure.tags
    },
    on: {
      "updated": _vm.updateTags
    }
  }), _c('v-textarea', {
    staticClass: "mt-2",
    attrs: {
      "value": _vm.measure.notes,
      "outlined": "",
      "hide-details": "",
      "label": "Notes",
      "rows": "4",
      "auto-grow": ""
    },
    on: {
      "change": _vm.updateNotes
    }
  })], 1)], 1), _c('v-menu', {
    attrs: {
      "offset-y": "",
      "min-width": "auto",
      "center": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "primary mt-4",
          attrs: {
            "block": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          staticClass: "mr-2"
        }, [_vm._v("mdi-image-area")]), _vm._v(_vm._s(_vm.measure.file ? "Replace" : "Upload") + " Image")], 1)];
      }
    }], null, false, 1459959247)
  }, [_c('v-list', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-list-item', {
    on: {
      "click": _vm.uploadCamera_click
    }
  }, [_c('v-list-item-icon', [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v("mdi-camera")])], 1), _c('v-list-item-title', [_vm._v("Take Picture")])], 1), _c('v-list-item', {
    on: {
      "click": _vm.uploadGallery_click
    }
  }, [_c('v-list-item-icon', [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v("mdi-folder-image")])], 1), _c('v-list-item-title', [_vm._v("Choose Image")])], 1), _c('v-list-item', {
    on: {
      "click": _vm.gridPaper_click
    }
  }, [_c('v-list-item-icon', [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v("mdi-grid")])], 1), _c('v-list-item-title', [_vm._v("Grid Paper")])], 1)], 1)], 1), _c('input', {
    ref: "cameraInput",
    staticClass: "d-none",
    attrs: {
      "type": "file",
      "accept": "image/*",
      "capture": ""
    },
    on: {
      "change": function change($event) {
        return _vm.uploadImage("cameraInput");
      }
    }
  }), _c('input', {
    ref: "galleryInput",
    staticClass: "d-none",
    attrs: {
      "type": "file",
      "accept": "image/*"
    },
    on: {
      "change": function change($event) {
        return _vm.uploadImage("galleryInput");
      }
    }
  })], 1) : _vm._e()], 1)] : _vm._e()], 2), _c('div', {
    staticClass: "pa-4",
    staticStyle: {
      "position": "fixed",
      "top": "0px",
      "right": "0px",
      "width": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "float-right",
    attrs: {
      "fab": "",
      "small": "",
      "text": "",
      "dark": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_c('v-icon', {
    attrs: {
      "large": ""
    }
  }, [_vm._v("mdi-close")])], 1)], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }