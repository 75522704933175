var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.PERMS.includes('FILEBOX') ? _vm._t("button", null, null, {
    click: _vm.buttonClick
  }) : _vm._e(), _c('v-dialog', {
    attrs: {
      "max-width": "1000"
    },
    model: {
      value: _vm.isOpen,
      callback: function callback($$v) {
        _vm.isOpen = $$v;
      },
      expression: "isOpen"
    }
  }, [_c('v-card', {
    attrs: {
      "disabled": _vm.loading,
      "loading": _vm.loading
    }
  }, [_c('v-card-title', {
    staticClass: "primary white--text"
  }, [_vm._v("Select File")]), _c('v-card-text', [_c('v-data-table', {
    staticClass: "dataTable mt-4",
    attrs: {
      "dense": "",
      "items-per-page": -1,
      "hide-default-footer": "",
      "items": _vm.files,
      "item-key": "xid",
      "headers": _vm.headers,
      "no-data-text": "No Files",
      "show-select": ""
    },
    scopedSlots: _vm._u([{
      key: "item.file",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "d-flex align-center clickFile"
        }, [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "color": _vm.ext(item.file).colour,
            "size": "28"
          }
        }, [_vm._v(_vm._s(_vm.ext(item.file).icon))]), _c('div', [_vm._v(_vm._s(item.file.filename))])], 1)];
      }
    }, {
      key: "item.created_at",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref3) {
              var on = _ref3.on,
                attrs = _ref3.attrs;
              return [_c('span', _vm._g(_vm._b({
                staticClass: "font-italic text-caption"
              }, 'span', attrs, false), on), [_vm._v(_vm._s(_vm.moment(item.created_at).from()))])];
            }
          }], null, true)
        }, [_c('span', [_vm._v(_vm._s(_vm.moment(item.created_at).format('MMM D, Y')))])])];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('v-btn', {
          staticClass: "primary--text",
          attrs: {
            "fab": "",
            "x-small": "",
            "text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.open_new_tab(item.file);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-open-in-new")])], 1)];
      }
    }]),
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  })], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "secondary",
    on: {
      "click": function click($event) {
        _vm.isOpen = false;
      }
    }
  }, [_vm._v("Close")]), _c('v-btn', {
    staticClass: "primary",
    attrs: {
      "disabled": !_vm.selected.length
    },
    on: {
      "click": _vm.selectFiles
    }
  }, [_vm._v("Select (" + _vm._s(_vm.selected.length) + ")")])], 1)], 1)], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }