<template>
  <div id='ImageViewer'>
     <!-- Do not use default "grow" transition, as it interferes with canvas scaling -->
    <TitleSelector ref='titleSelector' @input='updateTitle' />

    <v-dialog
      :value='active' v-if='active'
      fullscreen
      transition='dialog-bottom-transition'
    >
      <v-container id="container" fill-height fluid class='pt-14 pb-0'>
        <template v-if='measure'>

          <v-row style='height: 100%;'>
            <v-col :cols="viewer_fullscreen?12:8" class='xyellow' ref='toggleArea'>
              <template v-if='measure.file'>
                <div class='' style='position:absolute;top:10px;'>
                  <v-btn fab small text class='white--text' style='z-index:10;' @click='toggleFullscreen'>
                    <v-icon>{{viewer_fullscreen ? "mdi-fullscreen-exit": "mdi-fullscreen"}}</v-icon>
                  </v-btn>
                </div>
                <MarkupImage id='MarkupImage' v-if='markup.ready'
                  :src='markup.src'
                  :srcMarkup='markup.srcMarkup'
                  :save="markup.onSave"
                />
              </template>
            </v-col>

            <v-col :cols="4" class='' style='height: 100%; overflow-y: scroll;' v-if='!viewer_fullscreen'>

              <v-card :loading='loading' :disabled='loading'>
                <v-card-title class='primary white--text py-2'>
                  {{measure.label}}
                  <v-spacer/>
                  <v-btn fab x-small text class='white--text' @click='changeTitle'><v-icon>mdi-pencil</v-icon></v-btn>
                </v-card-title>
                <v-card-text class='pa-4'>

                  <MeasureTags :tagdata='measure.tags' @updated='updateTags' />

                  <v-textarea :value='measure.notes' outlined hide-details label='Notes' rows=4 class='mt-2' auto-grow @change='updateNotes'
                  />
                </v-card-text>
              </v-card>

              
              <v-menu offset-y min-width="auto" center>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn  v-bind="attrs"
          v-on="on" class='primary mt-4' block><v-icon class='mr-2'>mdi-image-area</v-icon>{{measure.file?"Replace":"Upload"}} Image</v-btn>
                </template>
                <v-list dense>
                  <v-list-item @click='uploadCamera_click'>
                    <v-list-item-icon><v-icon class='mr-2'>mdi-camera</v-icon></v-list-item-icon>
                    <v-list-item-title>Take Picture</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click='uploadGallery_click'>
                    <v-list-item-icon><v-icon class='mr-2'>mdi-folder-image</v-icon></v-list-item-icon>
                    <v-list-item-title>Choose Image</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click='gridPaper_click'>
                    <v-list-item-icon><v-icon class='mr-2'>mdi-grid</v-icon></v-list-item-icon>
                    <v-list-item-title>Grid Paper</v-list-item-title>
                  </v-list-item>
                </v-list>

              </v-menu>
              
              <input type='file' class='d-none' ref='cameraInput' @change='uploadImage("cameraInput")'  accept="image/*" capture />
              <input type='file' class='d-none' ref='galleryInput' @change='uploadImage("galleryInput")'  accept="image/*" />


            </v-col>

          </v-row>

        </template>
      </v-container>
      <div style='position:fixed;top:0px;right:0px;width:auto;' class='pa-4'>
        <v-btn fab small text dark class='float-right' @click='close'><v-icon large>mdi-close</v-icon></v-btn>
      </div>
    </v-dialog>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#container{
  background-color: #272727;
}

</style>

<script>
import { /* mapGetters, */  mapActions } from 'vuex';
import MarkupImage from '@/components/ImageViewer/MarkupImage'
import TitleSelector from './TitleSelector'
import MeasureTags from './MeasureTags'
export default {
  name: 'MeasureViewer',
  components: { MarkupImage, TitleSelector, MeasureTags },
  props: {

  },
  data(){
    return {
      loading: false,
      markup: { src: null, srcMarkup: null, onSave: this.saveMarkup, ready: false, },
      active: false,
      xid: null,
      measure: null,
      viewer_fullscreen: false,
    }
  },
  computed: {},
  methods: {
    ...mapActions("API", ["API"]),
    ...mapActions("UI", ["CONFIRM"]),

    open(measureId){
      this.markup.src = null;
      this.markup.srcMarkup = null;
      this.markup.ready = false;
      this.viewer_fullscreen = false;
      this.active = true;
      this.xid = measureId;
      this.refresh();
    },

    close(){
      this.active = false;
    },

    refresh(){
      this.measure = null;
      this.loading = true;
      this.API({ endpoint: `measures/${this.xid}` })
      .then(measure => {
        if(measure.file){
          let no_cache = `?${(new Date()).getTime()}`;
          this.markup.src = `${window.app.config.API}/measures/${this.xid}/image` + no_cache;
          if(measure.file.hasMarkup)
            this.loadMarkup().then(() => { this.markup.ready = true; });
          else this.markup.ready = true;
        }
        this.measure = measure;
      })
      .finally(() => { this.loading = false; })
    },

    uploadCamera_click(){
      if(this.measure.file){
        this.CONFIRM({ title: "Replace Image", message: 'The current image will be lost. Continue?' })
        .then(() => { this.$refs.cameraInput.click(); })
      }
      else this.$refs.cameraInput.click();
    },

    uploadGallery_click(){
      if(this.measure.file){
        this.CONFIRM({ title: "Replace Image", message: 'The current image will be lost. Continue?' })
        .then(() => { this.$refs.galleryInput.click(); })
      }
      else this.$refs.galleryInput.click();
    },

    async gridPaper_click(){
      if(this.measure.file)
        await this.CONFIRM({ title: "Replace Image", message: 'The current image will be lost. Continue?' })
      let data = { paper: 'grid' };
      this.API({ method: 'POST', endpoint: `measures/${this.xid}`, data })
        .then(() => {
          this.refresh();
          this.$emit('updated');
        });
    },

    uploadImage(ref){
      let f = this.$refs[ref].files[0] ?? null;
      this.$refs[ref].value = '';
      if(f){

        let resizeImage = function(file, callback){
          let image = new Image();
          let reader = new FileReader();
          reader.onload = (e) => image.src = e.target.result;
          image.onload = () => {
            let maxWidth = 1800;
            let maxHeight = 1200;
            let scale = Math.min(maxWidth / image.width, maxHeight / image.height, 1);
            let canvas = document.createElement('canvas');
            canvas.width = image.width * scale;
            canvas.height = image.height * scale;
            canvas.getContext('2d').drawImage(image, 0, 0, image.width * scale, image.height * scale);
            canvas.toBlob(callback, f.type);
          };
          reader.readAsDataURL(file);
        }
        
        resizeImage(f, resized => {
          let data = new FormData();
          data.append('file', resized, f.name);
          this.API({ method: 'POST', endpoint: `measures/${this.xid}`, data })
          .then(() => {
            this.refresh();
            this.$emit('updated');
          });
        });
        
      }
    },

    saveMarkup(markup){ return this.API({ method: 'POST', endpoint: `measures/${this.xid}`, data: { markup } }); },

    loadMarkup(){
      return this.API({ endpoint: `measures/${this.xid}/markup` })
      .then(markup => { this.markup.srcMarkup = markup; });
    },

    toggleFullscreen(){ this.viewer_fullscreen = !this.viewer_fullscreen; },

    changeTitle(){ this.$refs.titleSelector.open(); },

    updateTitle(label){
      this.loading = true;
      this.API({ method: 'POST', endpoint: `measures/${this.xid}`, data: { label } })
      .then(this.updated)
      .finally(() => { this.loading = false; })
    },

    updated(data){ this.measure = { ...this.measure, ...data }; this.$emit('updated'); },

    addTag_click(){ this.$refs.tagSelector.open(); },

    updateTags(tagData){
      this.loading = true;
      this.API({ method: 'POST', endpoint: `measures/${this.xid}`, data: { tags: tagData } })
      .then(this.updated)
      .finally(() => { this.loading = false; })
    },

    updateNotes(notes){
      this.loading = true;
      this.API({ method: 'POST', endpoint: `measures/${this.xid}`, data: { notes } })
      .then(this.updated)
      .finally(() => { this.loading = false; })
    }

  },
  created(){

  },
  mounted(){
  }
}
</script>
